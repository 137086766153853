import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Spin, SPACING, Button } from '@optii/ui-library';
import { UserAccessContext } from '@optii/shared';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { SelectOption } from '@optii/global';
import { InviteUserForm } from './form';
import {
  useGenerateEncryptedEmbedUrlQuery,
  useReportsQuery,
} from '../../../api/reports';
import {
  SIGMA_EVENT_NAME,
  SIGMA_EVENTS,
  SIGMA_ORIGIN,
  SUPPORTED_LANGUAGES,
} from '../../utils/constants';
import { UserDetails } from './userDetails';

const Iframe = styled.iframe`
  border: ${SPACING.NONE};
  width: 100%;
  height: 81vh;
  * {
    overflow: hidden !important;
    & div:first-child {
      overflow: hidden !important;
    }
  }
`;

export function UserManagement() {
  const { user } = useContext(UserAccessContext.Context);
  const [properties, setProperties] = useState<SelectOption[]>([]);

  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const userManagementRef = useRef<HTMLIFrameElement>(null);

  const { data } = useReportsQuery({
    variables: {
      filters: 'type==report,name==Users Management',
    },
  });

  const { data: src } = useGenerateEncryptedEmbedUrlQuery({
    variables: {
      embedKey: data?.reports.edges?.[0].node.embedKey,
      userEmail: user?.emailAddr,
      lng: SUPPORTED_LANGUAGES[
        user?.preferredLang as keyof typeof SUPPORTED_LANGUAGES
      ],
    },
    onError(error) {
      console.error(error);
    },
  });

  const onMessage = useCallback((event: MessageEvent<any>) => {
    const { data: sigmaData, source, origin } = event;

    if (event.data.type === 'workbook:loaded') {
      setLoading(false);
    }

    if (
      source === userManagementRef.current?.contentWindow &&
      origin === SIGMA_ORIGIN &&
      sigmaData.type === SIGMA_EVENTS.outbound
    ) {
      console.log('SIGMA EVENT:', event);
      if (sigmaData.name === SIGMA_EVENT_NAME.inviteUser) {
        const sigmaProperties = sigmaData.values.Properties.map(
          (property: { display_name: string; property_id: number }[]) => {
            const [propertyData] = property;

            return {
              label: propertyData.display_name,
              value: propertyData.property_id,
            };
          },
        );

        setProperties(sigmaProperties);

        setOpen(true);
      }

      if (sigmaData.name === SIGMA_EVENT_NAME.editUser) {
        const userInfo = sigmaData.values['User-Info'];
        const employmentInfo = sigmaData.values['Employment-Info'];

        setOpenDetails(true);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  return src?.url && !loading ? (
    <>
      <Button
        type="primary"
        onClick={() => {
          setOpenDetails(true);
        }}
      >
        View Details (Test)
      </Button>
      <Iframe
        title="User Management"
        id="user-management-iframe"
        data-testd="user-management-iframe"
        src={src.url}
        seamless
        ref={userManagementRef}
      />
      <InviteUserForm open={open} setOpen={setOpen} properties={properties} />

      <UserDetails open={openDetails} setOpen={setOpenDetails} />
    </>
  ) : (
    <Spin
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '70vh',
      }}
      spinning
      indicator={<LoadingOutlined spin />}
      size="large"
    />
  );
}
