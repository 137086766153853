type TDetails = (a: string) => string | null;

export const details = (t: TDetails) => ({
  // Checklists
  access_checklists: {
    displayName: t('rolesAndPermissions:Access Checklist'),
  },
  // Schedule
  sch_view: {
    displayName: t('rolesAndPermissions:Access Schedule'),
  },
  // Communication
  com_view: {
    displayName: t('rolesAndPermissions:Access Chat'),
  },
  com_chat_assist: {
    displayName: t('rolesAndPermissions:Access Chat Assist'),
  },
  com_chat_private_groups: {
    displayName: t('rolesAndPermissions:Access Dynamic Groups'),
  },

  com_chat_all_team_members: {
    displayName: t('rolesAndPermissions:Access All Team Members'),
  },
  // Reports
  report_job_activity_view: {
    displayName: t('rolesAndPermissions:Access Job Activity Report'),
  },
  report_team_member_activity_view: {
    displayName: t('rolesAndPermissions:Access Team Member Activity Report'),
  },
  report_project_cycle_summary_view: {
    displayName: t('rolesAndPermissions:Access Project Cycle Summary Report'),
  },
  report_above_property_access: {
    displayName: t('rolesAndPermissions:Access Above Property Report'),
  },
  report_checklist_detail_export: {
    displayName: t('rolesAndPermissions:Access Checklist Export'),
  },
  report_job_asset_export: {
    displayName: t('rolesAndPermissions:Access Job Asset Export'),
  },
  report_job_activity_export: {
    displayName: t('rolesAndPermissions:Access Job Export'),
  },
  report_pm_program_access: {
    displayName: t('rolesAndPermissions:Access PM Progress Report'),
  },
  report_daily_sheet_export: {
    displayName: t('rolesAndPermissions:Access Daily Sheet Export'),
  },
  access_housekeeping_history_report: {
    displayName: t('rolesAndPermissions:Access Housekeeping History Report'),
  },
  // Jobs
  job_view: { displayName: t('rolesAndPermissions:Access My Jobs') },
  job_all_view: {
    displayName: t('rolesAndPermissions:Access All Jobs by Status'),
  },
  job_autoassign_enable: {
    displayName: t('rolesAndPermissions:Allow Auto-Assignment'),
  },
  job_team_member_view: {
    displayName: t('rolesAndPermissions:Access All Jobs by Timeline'),
  },
  job_item_autocreate_enable: {
    displayName: t(
      'rolesAndPermissions:Allow Creation of Job Items On Add/Edit Jobs',
    ),
  },
  repeating_job_view: {
    displayName: t(
      'rolesAndPermissions:Allow the Management of Repeating Jobs',
    ),
  },
  // Settings - Jobs
  view_job_settings: {
    displayName: t('rolesAndPermissions:Access Job Settings'),
  },
  // Settings - Job Items
  view_job_items: {
    displayName: t('rolesAndPermissions:Access Job Items'),
  },
  // Settings - Notifications
  view_notifications: {
    displayName: t('rolesAndPermissions:Access Notifications'),
  },
  // Settings - Users
  set_usr_view: {
    displayName: t('rolesAndPermissions:Access Users and Invites'),
  },
  // Settings - Users PII
  set_usr_pii_view: {
    displayName: t('rolesAndPermissions:View Personal Information (PII)'),
  },
  // Settings - Orgs
  set_org_view: {
    displayName: t('rolesAndPermissions:Access Organizations'),
  },
  // Settings - Departments
  set_dpt_view: {
    displayName: t('rolesAndPermissions:Access Departments'),
  },
  // Settings - Roles and Permissions
  set_rol_view: {
    displayName: t('rolesAndPermissions:Access Roles'),
  },
  // Settings - Employment Types
  set_emp_view: {
    displayName: t('rolesAndPermissions:Access Employment Types'),
  },
  // Settings - Floor Plan
  set_floorplan_view: {
    displayName: t('rolesAndPermissions:Access Floor Plan'),
  },
  location_detail_view: {
    displayName: t('rolesAndPermissions:Access Location Details'),
  },
  // Settings - Assets
  set_asset_view: {
    displayName: t('rolesAndPermissions:Access Assets'),
  },
  // Settings - Projects
  eng_projects_view: {
    displayName: t('rolesAndPermissions:Access Projects'),
  },
  // Housekeeping
  housekeeping_jobs_view_job: {
    displayName: t('rolesAndPermissions:View Housekeeping Jobs'),
  },
  housekeeping_jobs_add_edit_job: {
    displayName: t('rolesAndPermissions:Add/Edit Housekeeping Jobs'),
  },
  housekeeping_jobs_delete_job: {
    displayName: t('rolesAndPermissions:Delete Housekeeping Jobs'),
  },
  room_drawer_read_only: {
    displayName: t('rolesAndPermissions:Access Rooms View - Read Only'),
  },
  room_drawer_full_access: {
    displayName: t('rolesAndPermissions:Access Rooms View - All Access'),
  },
  // above property
  above_prop_ctrl_view_checklist: {
    displayName: t('rolesAndPermissions:Access Checklist'),
  },
  above_prop_ctrl_view_job_management: {
    displayName: t('rolesAndPermissions:Access Job Management'),
  },

  // pms_resync: {
  //   displayName: t('common:PMS Resync'),
  // },
  // TODO: Uncomment if need heckbox permission for OnShift indicator in Settings
  // Settings - On Shift Indicator
  // on_shift_option_enable: {
  //   displayName: "Access On Shift Option"
  // }
});

export const PERMISSIONS = {
  schedule: {
    any: 'sch_*',
    view: 'sch_view',
  },
  jobs: {
    any: 'job_*',
    view: 'job_view',
    all: 'job_all_view', // kanban view
    autoassign: 'job_autoassign_enable',
    team_member: 'job_team_member_view', // team member view
    autocreate: 'job_item_autocreate_enable',
  },
  house_keeping: {
    any: 'housekeeping_*',
    view: 'housekeeping_jobs_view_job',
    add_edit: 'housekeeping_jobs_add_edit_job',
    delete: 'housekeeping_jobs_delete_job',
  },
  communications: {
    any: 'com_*',
    view: 'com_view',
    chatAssist: 'com_chat_assist',
    dynamicGroups: 'com_chat_private_groups',
    accessAllTeamMembers: 'com_chat_all_team_members',
  },
  aboveProperty: {
    above_prop_ctrl_view_job_management: 'above_prop_ctrl_view_job_management',
    above_prop_ctrl_view_checklist: 'above_prop_ctrl_view_checklist',
  },
  // pms_data: {
  //   pmsResync: 'pms_resync',
  // },
  reports: {
    any: 'report_*',
    jobActivityView: 'report_job_activity_view',
    teamMemberActivityView: 'report_team_member_activity_view',
    projectCycleSummaryView: 'report_project_cycle_summary_view',
    abovePropertyReportAccess: 'report_above_property_access',
    reportJobActivityExport: 'report_job_activity_export',
    reportJobAssetExport: 'report_job_asset_export',
    reportChecklistDetailExport: 'report_checklist_detail_export',
    reportPreventiveMaintenanceProgramAccess: 'report_pm_program_access',
    reportDailySheetExport: 'report_daily_sheet_export',
    accessHousekeepingHistoryReport: 'access_housekeeping_history_report',
  },
  engineering: {
    any: 'eng_*',
    accessProjects: 'eng_projects_view',
  },
  settings: {
    any: 'set_*',
    users: {
      any: 'set_usr*',
      view: 'set_usr_view',
      pii: 'set_usr_pii_view',
    },
    roles: {
      any: 'set_rol*',
      view: 'set_rol_view',
    },
    orgs: {
      any: 'set_org*',
      view: 'set_org_view',
    },
    empTypes: {
      any: 'set_emp*',
      view: 'set_emp_view',
    },
    checklists: {
      any: 'checklists*',
      view: 'access_checklists',
    },
    departments: {
      any: 'set_dpt*',
      view: 'set_dpt_view',
    },
    jobs: {
      any: 'view_job*',
      view: 'view_job_settings',
    },
    floorplan: {
      any: 'set_floorplan*',
      view: 'set_floorplan_view',
      locationDetailView: 'location_detail_view',
    },
    jobItems: {
      any: 'view_job*',
      view: 'view_job_items',
    },
    notifications: {
      any: 'view_notifications**',
      view: 'view_notifications',
    },
    repeatingJobs: {
      any: 'repeating_job*',
      view: 'repeating_job_view',
    },
    onShiftOption: 'on_shift_option_enable',
    assets: {
      any: 'set_asset*',
      view: 'set_asset_view',
    },
    rooms: {
      any: 'room_drawer*',
    },
  },
};
