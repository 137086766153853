import { JobType } from '@optii/jobs/api/jobs';
import { FormInstance } from '@optii/ui-library';
import React, { useMemo } from 'react';
import {
  useEmployeesQuery,
  EmployeeStatus,
  formatUserName,
  AUTO_ASSIGNEE_DATA,
  PERMISSIONS,
  useChecklistTemplatesQuery,
  useJobItemsQuery,
} from '@optii/global';
import { useAccess } from '@optii/shared';
import { Dayjs } from 'dayjs';
import { Housekeeping } from './Housekeeping';
import { Service } from './Service';
import {
  CustomDepartmentOption,
  CustomRoleOption,
  FormContext,
  FormContextProps,
  LocationWithAsset,
} from './context';
import { AssetNode } from '../types';

type Props = {
  jobType: JobType;
  form: FormInstance;
  shard: string;
  locationsWithAssetType: LocationWithAsset[];
  departments: CustomDepartmentOption[];
  departmentsLoading: boolean;
  roles: CustomRoleOption[];
  rolesLoading: boolean;
  assets?: AssetNode[];
  suggestion?: Dayjs;
  suggestionLoading: boolean;
};

export function FormFields({
  jobType,
  form,
  shard,
  locationsWithAssetType,
  assets,
  roles,
  rolesLoading,
  departments,
  departmentsLoading,
  suggestion,
  suggestionLoading,
}: Props) {
  const { can } = useAccess();

  const FORM_FIELDS: { [key: string]: React.JSX.Element } = {
    [JobType.Internal]: <Service form={form} />,
    [JobType.Guest]: <Service form={form} />,
    [JobType.Housekeeping]: <Housekeeping />,
  };

  const canAutoAssign = can(PERMISSIONS.jobs.autoassign);
  const canAddChecklist = can(PERMISSIONS.settings.checklists.view);

  // const itemWithAssociatedAssets = locationsWithAssetType.length > 0;

  const { data: jobItemsData, loading: jobItemsLoading } = useJobItemsQuery({
    context: {
      _shard: shard,
    },
    skip: !shard,
  });

  const { data: employeesData, loading: employeesLoading } = useEmployeesQuery({
    variables: {
      status: EmployeeStatus.Active,
    },
    context: {
      _shard: shard,
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    skip: !shard,
  });

  const { data: checklistData, loading: checklistsLoading } =
    useChecklistTemplatesQuery({
      variables: {
        orderBy: 'name_ASC',
      },
      context: {
        _shard: shard,
        _instance: 'node',
      },
      skip: !canAddChecklist || !shard,
    });

  const employeesOptions = useMemo(() => {
    const autoAssign = [
      {
        label: AUTO_ASSIGNEE_DATA.displayName,
        value: AUTO_ASSIGNEE_DATA.id.toString(),
        userName: AUTO_ASSIGNEE_DATA.userName,
        firstName: AUTO_ASSIGNEE_DATA.firstName,
        lastName: AUTO_ASSIGNEE_DATA.lastName,
      },
    ];

    const data =
      employeesData?.page.edges?.map(({ node }) => ({
        label: formatUserName(node),
        value: node.userId.toString(),
        userName: node.userName,
        firstName: node.userFirstName || '',
        lastName: node.userLastName || '',
      })) || [];

    if (canAutoAssign) {
      return autoAssign.concat(data);
    }

    return data;
  }, [canAutoAssign, employeesData]);

  const contextValue = useMemo((): FormContextProps => {
    const checklistOptions =
      checklistData?.page.edges?.map(({ node: { name, id } }) => ({
        label: name,
        value: id,
      })) || [];

    const itemOptions =
      jobItemsData?.page.edges?.map(({ node: { label, value } }) => {
        const isAssociatedWithAnAsset =
          assets?.some((asset) => asset?.assetType?.jobItem.id === value) ||
          false;

        return {
          label,
          value: label,
          isAssociatedWithAnAsset,
        };
      }) || [];

    return {
      shard,
      suggestion,
      suggestionLoading,
      assignees: {
        options: employeesOptions,
        loading: employeesLoading,
      },
      checklists: {
        options: checklistOptions,
        loading: checklistsLoading,
      },
      departments: {
        options: departments,
        loading: departmentsLoading,
      },
      items: {
        options: itemOptions,
        loading: jobItemsLoading,
      },

      roles: {
        options: roles,
        loading: rolesLoading,
      },
      assets: {
        options: locationsWithAssetType,
        loading: false,
      },
    };
  }, [
    shard,
    checklistData,
    jobItemsData,
    departments,
    roles,
    employeesOptions,
    jobItemsLoading,
    checklistsLoading,
    employeesLoading,
    rolesLoading,
    departmentsLoading,
    assets,
    locationsWithAssetType,
    suggestion,
    suggestionLoading,
  ]);

  return (
    <FormContext.Provider value={contextValue}>
      {FORM_FIELDS[jobType]}
    </FormContext.Provider>
  );
}
