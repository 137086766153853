import { DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  COLORS,
  ConfigProvider,
  Divider,
  Drawer,
  Flex,
  Form,
  FormItem,
  FormList,
  Input,
  Row,
  Select,
  SPACING,
  Typography,
  useForm,
} from '@optii/ui-library';
import React, {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption, useCreateBulkInvitationMutation } from '@optii/global';
import { Session, UserAccessContext } from '@optii/shared';
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { SECTION_TITLE_STYLE } from '../utils';
import { Positions } from './Positions';
import { Footer } from './Footer';
import Title from './Title';
import { FormValues } from './type';

type PhoneInputProps = {
  id?: string;
  value?: string;
  onChange?: (value: string) => void;
};

const CustomPhoneInput: FC<PhoneInputProps> = (props) => {
  const { value, onChange, id } = props;

  return (
    <span id={id}>
      <PhoneInput
        country="us"
        inputProps={{
          id,
        }}
        prefix="+"
        value={value}
        onChange={(_, __, ___, formattedValue) => {
          if (onChange) {
            onChange(formattedValue);
          }
        }}
      />
    </span>
  );
};

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  properties: SelectOption[];
};

export function InviteUserForm({ open, setOpen, properties }: Props) {
  const { t } = useTranslation(['common']);
  const { globalSnack } = useContext(Session);
  const { user } = useContext(UserAccessContext.Context);
  const [createBulkInvitation, { loading }] = useCreateBulkInvitationMutation({
    onError(error) {
      console.error(error);
    },
  });
  const [sendLater, setSendLater] = useState(false);
  const history = useHistory();
  const [form] = useForm();

  const onClose = () => {
    setOpen(false);
  };

  const accessibleProperties = properties.sort((a, b) =>
    a.label.localeCompare(b.label),
  );

  function onCancel() {
    history.goBack();
  }

  async function onFinish({
    emailAddr,
    mobileNum,
    properties: selectedProperties,
    userFirstName,
    userLastName,
  }: FormValues) {
    await Promise.all(
      selectedProperties.map(async (propertyDetails) => {
        const roles = propertyDetails.positions.map((position) => ({
          deptId: Number(position.department.value),
          deptName: position.department.label,
          roleId: Number(position.role.value),
          roleName: position.role.label,
        }));
        const payload = {
          emailAddr,
          mobileNum,
          userFirstName,
          userLastName,
          sendLater,
          sendText: false,
          userExists: false,
          userId: Number(user?.id),
          orgId: Number(propertyDetails.property.value),
          OrgName: propertyDetails.property.label,
          employmentTypeId: Number(propertyDetails.employmentType.value),
          employmentTypeName: propertyDetails.employmentType.label,
          roles,
        };

        await createBulkInvitation({
          variables: {
            createBulkInvitationInput: {
              invitations: [payload],
            },
          },
        });
      }),
    ).finally(() => {
      globalSnack({
        message: t('common:User added successfully.'),
        timeout: 6000,
      });
    });
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorTextPlaceholder: COLORS.neutral[5],
          },
        },
      }}
    >
      <Drawer
        open={open}
        width={675}
        onClose={onClose}
        destroyOnClose
        footer={
          <Footer
            setSendLater={setSendLater}
            onCancel={onCancel}
            form={form}
            loading={loading}
          />
        }
        title={<Title onClose={onClose} />}
      >
        <Form
          form={form}
          name="addTeamMember"
          onFinish={onFinish}
          onValuesChange={(c, b) => console.log(b)}
          initialValues={{
            properties: [
              {
                positions: [{}],
              },
            ],
          }}
        >
          <Typography.Paragraph style={SECTION_TITLE_STYLE}>
            Name and Contact Details
          </Typography.Paragraph>

          <Row align="bottom" gutter={SPACING.SIZE_MD}>
            <Col xs={24} sm={24} md={12}>
              <FormItem
                label={t('common:First Name')}
                name="userFirstName"
                required
                rules={[
                  {
                    required: true,
                    message: 'This is a required field',
                  },
                ]}
              >
                <Input />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <FormItem
                label={t('common:Last Name')}
                name="userLastName"
                required
                rules={[
                  {
                    required: true,
                    message: 'This is a required field',
                  },
                ]}
              >
                <Input />
              </FormItem>
            </Col>
          </Row>
          <Row align="bottom" gutter={SPACING.SIZE_MD}>
            <Col xs={24} sm={24} md={12}>
              <FormItem
                name="emailAddr"
                required
                label={t('common:Email Address')}
                rules={[
                  {
                    type: 'email',
                    message: 'Invalid email format',
                  },
                  {
                    required: true,
                    message: 'This is a required field.',
                  },
                ]}
              >
                <Input />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <FormItem
                name="mobileNum"
                required
                label={t('common:Mobile Number')}
              >
                <CustomPhoneInput />
              </FormItem>
            </Col>
          </Row>

          <Typography.Paragraph style={SECTION_TITLE_STYLE}>
            Employment Details
          </Typography.Paragraph>
          <FormList name="properties">
            {(propertyFields, { add, remove }) => (
              <>
                {propertyFields.map((propertyField) => (
                  <Fragment key={propertyField.key}>
                    <Flex align="start" gap={SPACING.SIZE_MD}>
                      <FormItem
                        name={[propertyField.name, 'property']}
                        required
                        normalize={(v) => ({
                          label: accessibleProperties.find(
                            (item) => v === item.value,
                          )?.label,
                          value: v,
                        })}
                        getValueProps={(valueProps) => ({
                          value: valueProps?.value,
                        })}
                        style={{
                          width: '100%',
                        }}
                        rules={[
                          {
                            required: true,
                            message: t('common:Property is a required field.'),
                          },
                        ]}
                      >
                        <Select
                          placeholder={t('common:Property')}
                          showSearch
                          optionFilterProp="label"
                          options={accessibleProperties.map((property) => {
                            const selectedProperties = form
                              .getFieldValue(['properties'])
                              .map(
                                (item: { property: { value: string } }) =>
                                  item?.property?.value,
                              );

                            return {
                              disabled: selectedProperties.includes(
                                property.value,
                              ),
                              ...property,
                            };
                          })}
                        />
                      </FormItem>
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={() => remove(propertyField.name)}
                        htmlType="button"
                        type="text"
                        styles={{
                          icon: {
                            color: COLORS.neutral[6],
                          },
                        }}
                        disabled={propertyFields.length === 1}
                      />
                    </Flex>
                    <FormItem
                      noStyle
                      shouldUpdate={(prev, curr) =>
                        prev.properties[propertyField.name]?.property !==
                        curr.properties[propertyField.name]?.property
                      }
                    >
                      {({ getFieldValue }) =>
                        getFieldValue([
                          'properties',
                          propertyField.name,
                          'property',
                        ]) ? (
                          <Positions
                            propertyPath={propertyField.name}
                            form={form}
                          />
                        ) : null
                      }
                    </FormItem>
                    <Divider />
                  </Fragment>
                ))}
                <Button type="primary" block onClick={() => add()}>
                  {t('common:Add Additional Property')}
                </Button>
              </>
            )}
          </FormList>
        </Form>
      </Drawer>
    </ConfigProvider>
  );
}
