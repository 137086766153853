import React, { Dispatch, SetStateAction } from 'react';
import {
  COLORS,
  ConfigProvider,
  DescriptionsProps,
  DescriptionText,
  Drawer,
  FONTS,
  SPACING,
  Table,
  TableProps,
  Typography,
} from '@optii/ui-library';
import { useTranslation } from 'react-i18next';
import { DRAWER_WIDTH } from '@optii/global';
import { Descriptions } from '@optii-solutions/ui-library';
import { Title } from './Title';
import { Footer } from './Footer';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export function UserDetails({ open, setOpen }: Props) {
  const { t } = useTranslation(['common']);
  const onClose = () => {
    setOpen(false);
  };

  const descriptionItems: DescriptionsProps['items'] = [
    {
      label: (
        <DescriptionText type="label">
          {t('common:First Name')}{' '}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" strong>
          Marge{' '}
        </DescriptionText>
      ),
      span: 1,
    },
    {
      label: (
        <DescriptionText type="label">{t('common:Last Name')} </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" strong>
          Simpson{' '}
        </DescriptionText>
      ),
      span: 'filled',
    },
    {
      label: (
        <DescriptionText type="label">
          {t('common:Email Address')}{' '}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" strong>
          marge@graciefilms.com{' '}
        </DescriptionText>
      ),
    },
    {
      label: (
        <DescriptionText type="label">{t('common:Mobile')} </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" strong>
          555-328-7468{' '}
        </DescriptionText>
      ),
    },
  ];

  const columns: TableProps['columns'] = [
    {
      dataIndex: 'property',
      key: 'property',
      title: t('common:Property'),
    },
    {
      dataIndex: 'employmentType',
      key: 'employmentType',
      title: t('common:Employment Type'),
    },
    {
      dataIndex: 'department',
      key: 'department',
      title: t('common:Department'),
    },
    {
      dataIndex: 'role',
      key: 'role',
      title: t('common:Role'),
    },
  ];

  return (
    <ConfigProvider
      theme={{
        token: {
          colorText: COLORS.neutral[8],
        },

        components: {
          Descriptions: {
            colorTextLabel: COLORS.neutral[8],
            colorTextBase: 'red',
          },
          Typography: {
            colorTextHeading: COLORS.neutral[8],
          },
        },
      }}
    >
      <Drawer
        open={open}
        title={
          <Title
            userName="Margie Simpson"
            onClose={onClose}
            onEdit={() => {}}
          />
        }
        footer={<Footer onClose={onClose} />}
        onClose={onClose}
        destroyOnClose
        id="user-details"
        width={DRAWER_WIDTH.LARGE}
      >
        <Descriptions
          title={
            <Typography.Text
              style={{
                letterSpacing: FONTS.medium.letterSpacing,
              }}
            >
              {t('common:Name and Contact Details')}
            </Typography.Text>
          }
          labelStyle={{
            color: COLORS.neutral[8],
            fontSize: FONTS.xSmall.size,
            fontWeight: 500,
            letterSpacing: FONTS.xSmall.letterSpacing,
            marginBottom: SPACING.SIZE_SM,
          }}
          contentStyle={{
            marginBottom: SPACING.SIZE_MD,
          }}
          colon={false}
          layout="vertical"
          items={descriptionItems}
        />

        <Typography.Paragraph
          style={{
            letterSpacing: FONTS.medium.letterSpacing,
            fontWeight: 500,
            marginTop: SPACING.SIZE_SM,
            marginBottom: SPACING.SIZE_XXL,
          }}
        >
          {t('common:Employment Details')}
        </Typography.Paragraph>
        <Table
          columns={columns}
          locale={{
            emptyText: 'No employment Details for this user',
          }}
        />
      </Drawer>
    </ConfigProvider>
  );
}
