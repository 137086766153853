import React from 'react';
import { Button, Flex, SPACING } from '@optii/ui-library';
import { useTranslation } from 'react-i18next';

type Props = {
  onClose: () => void;
};

export function Footer({ onClose }: Props) {
  const { t } = useTranslation(['common', 'fields']);
  return (
    <Flex align="center" justify="flex-end" gap={SPACING.SIZE_MD}>
      <Button type="text" onClick={onClose}>
        {t('common:Cancel')}
      </Button>
      <Button type="primary" htmlType="submit">
        {t('common:Update')}
      </Button>
    </Flex>
  );
}
