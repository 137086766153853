export const SYSTEM_OPTII_ADMIN = '-1';
export const FILE_SIZE_LIMIT = 20000000;

export const LIST_FETCH_POLICIES = {
  fetchPolicy: 'cache-and-network', // works for Departments, Users, and brokes (if we want to delete one and alone item on the page) JobItems, Floorplan -  does not update cache on refetch (https://github.com/apollographql/apollo-client/issues/6804). Removing this policy brokes pagination. Temporary solution is to use not refetch but get api call function.
  nextFetchPolicy: 'cache-first',
};

export const DRAWER_WIDTH = {
  LARGE: 976,
  DEFAULT: 675,
};

export const SIGMA_EVENTS = {
  outbound: 'action:outbound',
};

export const SUPPORTED_REPORT_LANGUAGES = {
  'es-MX': 'es',
  es: 'es',
  en: 'en',
};

export const SIGMA_EVENT_NAMES = {
  job: {
    createJob: 'Create-Job',
    editJob: 'Edit-Job',
  },
};
